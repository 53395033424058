.project-issue-group {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-medium);

  &.project-issue-group--new-requests {
    gap: 0;
    border-radius: var(--op-radius-x-large);
    box-shadow: 0 0 0 var(--op-border-width) var(--op-color-primary-plus-five);
    background-color: var(--op-color-primary-plus-eight);
    color: var(--op-color-primary-on-plus-eight);
    contain: paint;

    .group-header {
      color: var(--op-color-primary-on-plus-five-alt);
      border-color: var(--op-color-primary-plus-four);
      background-color: var(--op-color-primary-plus-five);
    }
  }

  .project-issue-group__header {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-small);
    box-shadow: 0 1px 0 0 var(--op-color-primary-plus-five);
    padding: var(--op-space-medium);
    background-color: var(--op-color-primary-plus-seven);
    color: var(--op-color-primary-on-plus-seven);
  }

  .project-issue-group__body {
    padding: var(--op-space-medium);
  }

  .project-issue-group__heading {
    align-self: baseline;
    font-weight: var(--op-font-weight-semi-bold);
    font-size: var(--op-font-x-small);
    border: var(--op-border-width) solid var(--op-color-border);
    border-radius: var(--op-radius-pill);
    padding: var(--op-space-x-small) var(--op-space-medium);
    background-color: var(--op-color-neutral-plus-six);
    color: var(--op-color-neutral-on-plus-six-alt);
  }

  .project-issue-group__no-content {
    text-wrap: balance;
  }

  .project-issue-group__subheading {
    font-weight: var(--op-font-weight-normal);
    font-size: var(--op-font-x-small);
  }

  .project-issue-group__triage {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);
  }
}

a.project-issue {
  .project-issue__title {
    color: var(--op-color-primary-on-plus-five-alt);
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 0 0 var(--op-border-width-large) var(--op-color-primary-plus-three),
                0 1px 0 var(--op-border-width-large) var(--op-color-primary-plus-four),
                0 2px 3px 0 var(--op-color-primary-plus-three);
  }
}

.project-issue--action {
  display: flex;
  align-items: center;
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-primary-on-plus-max);
  border-radius: var(--op-radius-large);
  box-shadow: 0 1px 0 var(--op-border-width) var(--op-color-border);
  padding-block: var(--op-space-medium);
  padding-inline: var(--op-space-large);
  line-height: var(--op-line-height-dense);
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 0 var(--op-border-width-large) var(--op-color-primary-plus-three),
                0 1px 0 var(--op-border-width-large) var(--op-color-primary-plus-four),
                0 2px 3px 0 var(--op-color-primary-plus-three);
  }
}

.project-issue {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-primary-on-plus-max);
  border-radius: var(--op-radius-large);
  box-shadow: 0 1px 0 var(--op-border-width) var(--op-color-border);
  text-decoration: none;
  padding: var(--op-space-medium);
  padding-top: var(--op-space-small);
  transition: var(--op-transition-input);
  line-height: var(--op-line-height-dense);

  .project-issue__details {
    display: grid;
    grid-template-columns: 1fr calc(var(--op-size-unit) * 60);
    gap: var(--op-space-large);
  }

  .project-issue__title {
    text-transform: capitalize;
    font-weight: var(--op-font-weight-semi-bold);
  }

  .project-issue__meta {
    display: flex;
    justify-content: space-between;
  }

  .project-issue__badges {
    display: flex;
    gap: var(--op-space-small);
  }

  &.project-issue--triaged {
    color: var(--op-color-primary-on-plus-eight-alt);
    background-color: var(--op-color-primary-plus-eight);
    box-shadow: 0 0 0 var(--op-border-width) var(--op-color-primary-plus-five),
    0 1px 0 var(--op-border-width) var(--op-color-primary-plus-four);
    font-size: var(--op-font-x-small);
    font-weight: var(--op-font-weight-semi-bold);
    padding: var(--op-space-medium);
  }

  &.project-issue--in-review {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: var(--op-space-x-small);
    padding: var(--op-space-medium);

    &:hover {
      box-shadow: 0 1px 0 var(--op-border-width) var(--op-color-border);
    }
  }

  &.project-issue--epic-task {
    display: grid;
    grid-template-areas: 'title priority' 'meta meta';
    grid-template-columns: 1fr auto;
    grid-column-gap: var(--op-space-large);
    grid-row-gap: var(--op-space-medium);

    .project-issue__title {
      grid-area: title;
    }

    .project-issue__priority {
      display: flex;
      gap: var(--op-space-medium);
      align-items: center;
    }

    .project-issue__meta {
      grid-area: meta;
      display: flex;
      gap: var(--op-space-medium);
      font-style: italic;
      color: var(--op-color-on-background-alt);
    }
  }
}
