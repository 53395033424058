.display-none {
  display: none;
}

.hidden {
  display: none;
}

.container {
  max-width: var(--op-breakpoint-large);
}

.rotate-90 {
  transform: rotate(90deg);
  writing-mode: vertical-lr; // Ensure padding or margin don't change axis
}

.mobile-hidden {
  @media(max-width: 768px) {
    display: none !important;
  }
}

.desktop-hidden {
  @media(min-width: 768px) {
    display: none !important;
  }
}

.overflow-x-scroll {
  overflow-x: auto;
}

.padding-x {
  padding: 0 var(--op-space-medium);
}
