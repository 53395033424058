.form-group .ts-wrapper {
  grid-column: 1/3;
}

.ts-wrapper .ts-control, .ts-wrapper.single.input-active .ts-control {
  border-radius: var(--gc-radius-default);
  background-color: var(--op-color-neutral-plus-max);
  box-shadow: var(--op-border-all) var(--op-color-border);
  font-weight: var(--op-font-weight-normal);
  font-size: var(--op-font-small);

  &> input {
    color: var(--op-color-neutral-on-plus-max)
  }

  .form-group--error & {
    box-shadow: var(--op-border-all) var(--op-color-alerts-danger-base);

    &:hover {
      background-color: var(--op-color-alerts-danger-plus-seven);
    }
  }
}

.ts-wrapper.plugin-remove_button .item {
  background-color: var(--op-color-primary-base);
  color: var(--op-color-primary-on-base);
  padding: var(--op-space-2x-small) var(--op-space-small);
  border-radius: var(--gc-radius-default);
}

.ts-wrapper .ts-dropdown {
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);

  & .optgroup-header {
    background-color: var(--op-color-neutral-plus-five);
    color: var(--op-color-neutral-on-plus-five);
    font-weight: var(--op-font-weight-semi-bold);
  }
}
