.btn {
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);

  &:not(.btn--no-border) {
    border: var(--linear-button-border);
    box-shadow: var(--linear-button-shadow);
  }

  // Default Active State
  &.btn--active {
    background-color: var(--op-color-neutral-plus-seven);
    color: var(--op-color-neutral-on-plus-seven);
  }

  // Default Hover State
  &:hover {
    background-color: var(--op-color-neutral-plus-seven);
    color: var(--op-color-neutral-on-plus-seven);

    &:not(.btn--no-border) {
      border: var(--linear-button-border);
      box-shadow: var(--linear-button-shadow);
    }
  }
}

.btn--comment {
  background-color: var(--op-color-rms-periwinkle-plus-six);
  color: var(--op-color-rms-periwinkle-on-base);
  border-radius: var(--op-radius-circle);
}
