.card {
  --_op-card-padding: var(--op-space-x-small) var(--op-space-small);

  border: var(--linear-button-border);
  box-shadow: var(--linear-button-shadow);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-2x-small);

  &.card--task:hover {
    background-color: var(--op-color-neutral-plus-eight);
  }
}

a.card { text-decoration: none; }
