sl-menu-item {
  &::part(label) {
    font-size: var(--op-font-small);
    text-decoration: none !important;
  }
}

.menu-item {
  color: var(--op-color-on-neutral-plus-seven);
  text-decoration: none;
  padding: 0;

  sl-menu-item::part(label) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--op-space-x-small);
  }
}
