// Wrapper for the projects/show and epic/show pages
%details {
  display: grid;
  height: 100%;
  overflow: hidden;
}

.project-details {
  @extend %details;

  grid-template-areas: 'nav-tabs sidebar' 'main sidebar';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;

  .details-navigation {
    grid-area: nav-tabs;
  }
}

.epic-details {
  @extend %details;

  grid-template-areas: 'sidebar main';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
}

// Container for the main contents on the projects/show and epic/show pages
.details-items {
  overflow: auto;

  .details-items__content {
    grid-area: main;
    padding: var(--op-space-large);
    padding-bottom: var(--op-space-3x-large);
    display: flex;
    flex-direction: column;
    gap: var(--op-space-x-large);
    background-color: var(--op-color-background);
    color: var(--op-color-on-background);
    width: 100%;
    max-width: var(--op-breakpoint-medium);
    margin: 0 auto;
  }

  .details-items__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .details-items__heading {
    font-size: var(--op-font-large);
    font-weight: var(--op-font-weight-semi-bold);
  }
}

// Neutral-colored container used for project updates as
// well as containing Linear issues of a given status
%collection {
  color: var(--op-color-neutral-on-plus-seven);
  background-color: var(--op-color-neutral-plus-seven);
  box-shadow: inset 0 1px 3px 0 var(--op-color-neutral-plus-five);
  border-radius: var(--op-radius-x-large);
  padding: var(--op-space-medium);
}

.status-group {
  @extend %collection;

  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
}

.project-updates {
  @extend %collection;

  display: grid;
  grid-template-columns: 1fr 30rem;
  grid-column-gap: var(--op-space-x-large);

  p {
    margin: 0;
    font-size: var(--op-font-small);
    line-height: var(--op-line-height-looser);
  }

  em, strong {
    font-weight: var(--op-font-weight-semi-bold);
    font-style: normal;
  }

  .project-updates__description {
    border: var(--op-border-width) solid var(--op-color-border);
    border-radius: var(--op-radius-large);
    background-color: var(--op-color-neutral-plus-eight);
    color: var(--op-color-neutral-on-plus-eight);
    padding: var(--op-space-medium) var(--op-space-large);
    display: flex;
    flex-direction: column;
    gap: var(--op-space-2x-small);

    p:not(:first-child) {
      margin-top: var(--op-space-medium);
    }
  }

  .project-updates__meta-items {
    color: var(--op-color-neutral-on-plus-seven-alt);
  }
}

.group-header {
  align-self: baseline;
  font-weight: var(--op-font-weight-semi-bold);
  font-size: var(--op-font-x-small);
  border: var(--op-border-width) solid var(--op-color-border);
  border-radius: var(--op-radius-pill);
  padding: var(--op-space-x-small) var(--op-space-medium);
  background-color: var(--op-color-neutral-plus-six);
  color: var(--op-color-neutral-on-plus-six-alt);
}

// Sidebar on the project/show and epic/show pages
.details-sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  color: var(--op-color-on-background);
  background-color: var(--op-color-background);
  box-shadow: var(--op-border-left) var(--op-color-border);
  z-index: 10;
  overflow: hidden;

  .details-sidebar__header {
    display: grid;
    align-items: center;
    background-color: var(--op-color-background);
    color: var(--op-color-on-background);
    font-weight: var(--op-font-weight-semi-bold);
    padding: var(--op-space-medium) var(--op-space-2x-large);
    border-bottom: var(--op-border-width) solid var(--op-color-border);
    border-top: var(--op-border-width) solid var(--op-color-border);
    flex-shrink: 0;
    z-index: var(--op-z-index-sidebar-header);
  }

  .details-sidebar__content {
    padding: var(--op-space-large) var(--op-space-2x-large);
    overflow: auto;
  }

  p {
    font-size: inherit;
  }
}

// Specific details for the project/show sidebar
.details-sidebar--daily-update {
  //border-left: var(--op-border-width) solid var(--op-color-border);
  width: var(--op-project-sidebar-width);

  .daily-update__header {
    display: grid;
    text-align: center;
    align-items: center;
    padding-block: 0;
    height: var(--op-subheader-height);
  }

  .daily-update__summary {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    gap: var(--op-space-small);

    border-bottom: var(--op-border-width) solid var(--op-color-border);
    padding: var(--op-space-large) var(--op-space-2x-large);

    .daily-update__summary-heading {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: var(--op-space-small);

      em {
        font-style: normal;
        font-weight: var(--op-font-weight-semi-bold);
      }
    }

    .daily-update__summary-scope {
      height: var(--op-scope-chart-height);
    }

    .daily-update__summary-metadata {
      font-size: var(--op-font-x-small)
    }
  }
}

// Specific details for the epic/show sidebar
.details-sidebar--epic {
  border-right: var(--op-border-width) solid var(--op-color-border);
  width: var(--op-epic-sidebar-width);

  .details-sidebar__header {
    gap: var(--op-space-x-small);

    .details-sidebar--epic__heading {
      --_op-line-height: var(--op-line-height-base);
      --_op-font-size:  var(--op-font-large);

      font-size: var(--_op-font-size);
      line-height: var(--_op-line-height);

      .details-sidebar--epic__status {
        display: inline-flex;
        height: calc(var(--_op-line-height) * var(--_op-font-size));
        align-items: center;
        vertical-align: top;
      }
    }

    .details-sidebar--epic__back {
      padding: 0;
      width: min-content;
      min-height: min-content;
      padding-right: var(--op-space-x-small);
      gap: var(--op-space-x-small);
    }
  }
}
