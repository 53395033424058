.kanban__container {
  width: 100%;
  height: 100%
}

.kanban__header {
  padding: var(--op-space-small) var(--op-space-medium);
  box-shadow: var(--op-border-bottom) var(--op-color-border);
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--op-subheader-height);
  align-items: center;
  gap: var(--op-space-small);
}

.kanban__wrapper {
  height: 100%;
  &.kanban--with-sidebar {
    display: grid;
    height: 100%;
    overflow: hidden;
    grid-template-areas: 'main sidebar';
    grid-template-columns: 1fr auto;
  }
}

.kanban {
  padding: var(--op-space-medium) var(--op-space-large);
  overflow-x: auto;
  display: flex;
  gap: var(--op-space-small);
  width: 100%;
  height: calc(100% - var(--op-subheader-height));

  // --op-breakpoint-sm
  @media(width < 768px) {
    scroll-snap-type: x mandatory;
  }
}

.kanban__column {
  --column-width: 30rem;
  min-width: var(--column-width);
  width: var(--column-width);
  overflow-y: auto;
  background-color: var(--op-color-neutral-plus-nine);
  border-radius: var(--op-radius-large);
  padding: var(--op-space-small);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
  height: 100%;

  // --op-breakpoint-sm
  @media(width < 768px) {
    --column-width: 100vw;
    scroll-snap-align: start;
  }
}

.kanban__issue-identifier {
  display: flex;
  align-items: center;
  gap: var(--op-space-x-small);
  font-size: var(--op-font-small);
  color: var(--op-color-neutral-plus-one);
  overflow: hidden;
  @media(max-width: 768px) {
    align-self: flex-start;
  }
}

.kanban__issue-identifier-number {
  white-space: nowrap;
}

.kanban__issue-header {
  display: grid;
  align-items: center;
  gap: var(--op-space-x-small);
  grid-template-columns: 1fr auto;
}

.kanban__issue-identifier-parent {
  font-size: var(--op-font-x-small);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.kanban__issue-title {
  --_lcv-kanban-title-icon-padding: 3px;

  display: flex;
  align-items: start;
  gap: var(--op-space-2x-small);

  .icon {
    padding-block-start: var(--_lcv-kanban-title-icon-padding);
  }
}

.kanban__column-title {
  color: var(--op-color-neutral-minus-two);
}

.kanban__column-total {
  display: flex;
  align-items: center;
  gap: var(--op-space-2x-small);
  font-size: var(--op-font-small);
  color: var(--op-color-neutral-plus-one);
  font-weight: var(--op-font-weight-normal);
}

.kanban__avatar {
  align-items: center;
  justify-content: center;
  line-height: 0;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;

  &.kanban__avatar--empty {
    position: relative;
    background-color: var(--op-color-primary-base);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.kanban__avatar-initials {
  font-weight: light;
  color: var(--op-color-neutral-plus-max);
  font-size: var(--op-font-2x-small);
}

.kanban__card-section {
  position: relative;
  background-color: var(--op-color-neutral-plus-max);
  border-radius: var(--op-radius-large);
  padding: var(--op-space-small);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);

  @media(max-width: 768px) {
    overflow-x: auto;
    max-width: 92vw;
  }
}

a.kanban__child-issue {
  text-decoration: none;
  padding: var(--op-space-x-small);
  color: var(--op-color-neutral-on-max);
  font-size: var(--op-font-small);
  border-radius: var(--op-radius-medium);

  &:hover {
    background-color: var(--op-color-neutral-plus-max);
    box-shadow: var(--op-border-all) var(--op-color-primary-plus-five);
  }
}

.kanban__comment-reactions {
  display: flex;
  align-items: center;
  gap: var(--op-space-xs);
}

.kanban__search {
  width: 30rem;
  min-width: 20rem;
  position: relative;
  @media(max-width: 768px) {
    width: 20rem;
  }
  input.form-control {
    padding-left: var(--op-space-3x-large);
  }
}

select.kanban__label-select.form-control:not([multiple], [type='radio'], [type='checkbox']) {
  width: 15rem;
}

.kanban__search-icon {
  position: absolute;
  top: var(--op-space-2x-small);
  left: var(--op-space-x-small);
}

.kanban__comment {
  p {
    margin: 0;
  }
}

.kanban__progress {
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: flex-start;

  &::before {
    font-size: var(--op-font-x-small);
    content: var(--progress-string);
  }
}

.kanban__progress-bar {
  --progress: 0%;
  height: 90%;
  width: var(--progress);
  border-radius: var(--op-radius-small);
  background-color: var(--op-color-primary-plus-five);
  display: flex;
  justify-content: center;
}

.kanban__total-progress-bar {
  position: relative;
  height: 2.2rem;
  background-color: var(--op-color-alerts-notice-plus-eight);
  box-shadow: var(--op-border-all) var(--op-color-alerts-notice-plus-five);
  padding: var(--op-space-2x-small);
  display: flex;
  justify-content: flex-start;
  width: 30rem;
  border-radius: var(--op-radius-large);
  &::before {
    left: 8rem;
    position: absolute;
    font-size: var(--op-font-x-small);
    content: var(--progress-string);
  }

  .kanban__progress-bar {
    border-radius: var(--op-radius-medium);
    height: 100%;
    background-color: var(--op-color-alerts-notice-plus-five);
  }

  .kanban__progress {
    &::before {
      width: 30rem;
      left: var(--op-font-x-small);
    }
  }
}
