.form-row {
  display: flex;
  gap: var(--op-space-x-large);

  .form-group {
    flex: 1 1 0px;

    &.form-group--compact {
      gap: 0;
      padding: var(--op-space-small) 0;
    }

  }

  &.form-row__actions {
    gap: var(--op-space-medium);
    align-items: center;
    justify-content: flex-end;
    margin-bottom: var(--op-space-medium);
  }

  .form-group--error~.form-row__actions {
    margin-bottom: var(--op-space-3x-large);
  }
}

.form-group {
  padding: var(--op-space-small);
}

.form-control:not([type="radio"], [type="checkbox"]):focus-visible:not([readonly]) {
  box-shadow: var(--linear-button-shadow);
  background-color: var(--op-color-neutral-plus-max);
  border: var(--linear-button-border);
}

.form-control:not([type="radio"], [type="checkbox"]):not([readonly]) {
  background-color: var(--op-color-neutral-plus-max);
}

textarea.form__expanded-trix.form-control:not([type='radio'], [type='checkbox']) {
  height: 24rem;
}
