// Optics
@import '@rolemodel/optics/dist/css/optics.css';

// Vendors
@import 'tom-select/dist/scss/tom-select';

// Optics Addons
@import '@rolemodel/optics/dist/css/addons/panel.css';
@import '@rolemodel/optics/dist/css/addons/tom-select.css';
@import '@rolemodel/optics/dist/css/addons/fonts/phosphor_icons';

// Font Customization
@import 'core/fonts/text_fonts';

// Theme Customization
@import 'core/theme/pulse-theme-core';
@import 'core/theme/pulse-theme-light';

// Core Customization
@import 'core/layout';
@import 'core/utilities';

// Vendor Customization
@import 'vendors/custom-tom-select';
@import 'vendors/shoelace';

// Optics Component Customization
@import 'components/optics-overrides/badge';
@import 'components/optics-overrides/breadcrumbs';
@import 'components/optics-overrides/button';
@import 'components/optics-overrides/button_group';
@import 'components/optics-overrides/card';
@import 'components/optics-overrides/form';
@import 'components/optics-overrides/icon';
@import 'components/optics-overrides/navbar';
@import 'components/optics-overrides/button';
@import 'components/optics-overrides/modal';
@import 'components/optics-overrides/tab';

// Components
@import 'components/detail-navigation';
@import 'components/kanban';
@import 'components/login';
@import 'components/menu';
@import 'components/project-issue';
@import 'components/team';
@import 'components/sidebar';

// Layout
@import 'layouts/details';
