.details-navigation {
  --lcv-color-dashboard-shadow: rgba(0, 0, 0, 0.1);
  --lcv-details-navigation-tab-shadow: var(--op-border-x);

  display: flex;
  justify-content: flex-end;

  box-shadow:
    inset var(--op-border-top) var(--op-color-border),
    0 1px 5px 0 var(--lcv-color-dashboard-shadow),
    0 1px 20px 0 var(--lcv-color-dashboard-shadow);
  padding-inline-start: var(--op-space-large);
  height: var(--op-subheader-height);
  z-index: var(--op-z-index-breadcrumb-header);

  .tab-group {
    height: calc(var(--op-subheader-height) - 1px);
  }

  .tab {
    height: 100%;
    align-content: center;
    box-shadow: var(--lcv-details-navigation-tab-shadow);

    &.tab--active {
      box-shadow:
        var(--lcv-details-navigation-tab-shadow),
        var(--__op-tab-indicator) var(--op-color-primary-plus-one);
    }
  }
}
