.breadcrumbs {
  align-items: center;
  gap: 0;

  .breadcrumbs__text {
    font-weight: var(--op-font-weight-semi-bold);
    font-size: var(--op-font-medium);
    padding-inline-start: var(--op-space-x-small);

    &.breadcrumbs__text--solo {
      font-size: var(--op-font-2x-large);
      padding-inline: 0 var(--op-space-x-small);
    }
  }
}
