:root {
  --op-transition-modal: all 100ms linear;
}

.modal {
  --_op-modal-width: calc(200 * var(--op-size-unit));
  --_op-modal-max-height: calc(150* var(--op-size-unit));
}

.modal-wrapper {
  --_op-modal-backdrop-active-opacity: 0.2;
}

.modal-wrapper.modal--no-footer {
  .modal__footer {
    display: none;
  }
}

.modal .modal__body {
  height: var(--_op-modal-max-height);
  padding: var(--op-space-large);
  background-color: var(--op-color-neutral-plus-eight);
}
