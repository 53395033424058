.app__login {
  display: flex;
  justify-content: center;
  padding-block-start: var(--op-space-x-large);
}

.card.card--login {
  --_lcv-login-image-width: calc(25 * var(--op-size-unit)); /* 100px */
  --_lcv-login-max-width: calc(100 * var(--op-size-unit)); /* 400px */

  width: 100%;
  max-width: var(--_lcv-login-max-width);

  .card__header {
    display: flex;
    justify-content: center;

    img {
      width: var(--_lcv-login-image-width);
    }
  }

  .card__body {
    padding-block: 0;
  }

  .card__footer {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    gap: var(--op-space-x-small);
  }
}
