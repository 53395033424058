.app-body {
  --op-color-background: var(--op-color-neutral-plus-max);

  display: flex;
  flex-flow: column;
  height: 100vh;
}

.app__content {
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

// Wrapper for the main index pages
.index-page {
  padding-top: var(--op-space-large);
  padding-bottom: var(--op-space-3x-large);
  overflow: auto;
}
