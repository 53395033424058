:root {
  // Neutral Scale
  --op-color-neutral-plus-nine: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 98.5%);

  // RMS Color Scales
  --op-color-rms-periwinkle-original: hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) var(--op-color-rms-periwinkle-l));

  // RMS Periwinkle Scale
  --op-color-rms-periwinkle-plus-max: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 12%)
  );
  --op-color-rms-periwinkle-plus-eight: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 14%)
  );
  --op-color-rms-periwinkle-plus-seven: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 96%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 16%)
  );
  --op-color-rms-periwinkle-plus-six: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 94%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%)
  );
  --op-color-rms-periwinkle-plus-five: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 90%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 24%)
  );
  --op-color-rms-periwinkle-plus-four: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 84%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 26%)
  );
  --op-color-rms-periwinkle-plus-three: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 70%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 29%)
  );
  --op-color-rms-periwinkle-plus-two: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 64%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 32%)
  );
  --op-color-rms-periwinkle-plus-one: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 45%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 35%)
  );
  --op-color-rms-periwinkle-base: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 40%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 38%)
  );
  --op-color-rms-periwinkle-minus-one: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 36%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 40%)
  );
  --op-color-rms-periwinkle-minus-two: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 32%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 45%)
  );
  --op-color-rms-periwinkle-minus-three: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 28%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 48%)
  );
  --op-color-rms-periwinkle-minus-four: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 24%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 52%)
  );
  --op-color-rms-periwinkle-minus-five: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 64%)
  );
  --op-color-rms-periwinkle-minus-six: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 16%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 72%)
  );
  --op-color-rms-periwinkle-minus-seven: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 8%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 80%)
  );
  --op-color-rms-periwinkle-minus-eight: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 4%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 88%)
  );
  --op-color-rms-periwinkle-minus-max: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 0%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%)
  );

  /* On Scale */
  --op-color-rms-periwinkle-on-plus-max: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 0%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%)
  );
  --op-color-rms-periwinkle-on-plus-max-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 78%)
  );
  --op-color-rms-periwinkle-on-plus-eight: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 4%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 88%)
  );
  --op-color-rms-periwinkle-on-plus-eight-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 24%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 70%)
  );
  --op-color-rms-periwinkle-on-plus-seven: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 8%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 80%)
  );
  --op-color-rms-periwinkle-on-plus-seven-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 28%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 64%)
  );
  --op-color-rms-periwinkle-on-plus-six: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 16%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 72%)
  );
  --op-color-rms-periwinkle-on-plus-six-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 26%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 96%)
  );
  --op-color-rms-periwinkle-on-plus-five: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 72%)
  );
  --op-color-rms-periwinkle-on-plus-five-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 40%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 86%)
  );
  --op-color-rms-periwinkle-on-plus-four: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 24%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 80%)
  );
  --op-color-rms-periwinkle-on-plus-four-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 4%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 92%)
  );
  --op-color-rms-periwinkle-on-plus-three: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 78%)
  );
  --op-color-rms-periwinkle-on-plus-three-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 10%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%)
  );
  --op-color-rms-periwinkle-on-plus-two: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 16%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 80%)
  );
  --op-color-rms-periwinkle-on-plus-two-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 6%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 92%)
  );
  --op-color-rms-periwinkle-on-plus-one: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 80%)
  );
  --op-color-rms-periwinkle-on-plus-one-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 95%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%)
  );
  --op-color-rms-periwinkle-on-base: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%)
  );
  --op-color-rms-periwinkle-on-base-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 88%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 84%)
  );
  --op-color-rms-periwinkle-on-minus-one: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 94%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%)
  );
  --op-color-rms-periwinkle-on-minus-one-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 82%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 90%)
  );
  --op-color-rms-periwinkle-on-minus-two: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 90%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%)
  );
  --op-color-rms-periwinkle-on-minus-two-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 78%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 92%)
  );
  --op-color-rms-periwinkle-on-minus-three: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 86%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%)
  );
  --op-color-rms-periwinkle-on-minus-three-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 74%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 96%)
  );
  --op-color-rms-periwinkle-on-minus-four: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 84%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 2%)
  );
  --op-color-rms-periwinkle-on-minus-four-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 72%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 2%)
  );
  --op-color-rms-periwinkle-on-minus-five: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 88%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 2%)
  );
  --op-color-rms-periwinkle-on-minus-five-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 78%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 20%)
  );
  --op-color-rms-periwinkle-on-minus-six: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 94%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 8%)
  );
  --op-color-rms-periwinkle-on-minus-six-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 82%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 26%)
  );
  --op-color-rms-periwinkle-on-minus-seven: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 96%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 8%)
  );
  --op-color-rms-periwinkle-on-minus-seven-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 84%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 34%)
  );
  --op-color-rms-periwinkle-on-minus-eight: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 98%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 4%)
  );
  --op-color-rms-periwinkle-on-minus-eight-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 86%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 38%)
  );
  --op-color-rms-periwinkle-on-minus-max: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 100%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 0%)
  );
  --op-color-rms-periwinkle-on-minus-max-alt: light-dark(
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 88%),
    hsl(var(--op-color-rms-periwinkle-h) var(--op-color-rms-periwinkle-s) 38%)
  );
}
