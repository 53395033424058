:root {
  // Colors
  --op-color-white: hsl(0, 0%, 99%);
  --op-color-black: hsl(193, 88%, 3%);

  --op-secondary-h: 29;
  --op-secondary-s: 100%;
  --op-secondary-l: 43%;

  --op-color-rms-periwinkle-h: 256deg;
  --op-color-rms-periwinkle-s: 81%;
  --op-color-rms-periwinkle-l: 78%;

  // Scope Chart Colors
  --app-scope-total-color: rgb(89, 90, 92);
  --app-scope-started-color: rgb(242, 190, 0);
  --app-scope-completed-color: rgb(94, 106, 210);

  // Z-Index
  --op-z-index-sidebar-header: 400;
  --op-z-index-breadcrumb-header: 400;

  // Sizes
  --op-subheader-height: calc(12 * var(--op-size-unit)); // 48px;
  --op-epic-sidebar-width: calc(120 * var(--op-size-unit)); // 480px;
  --op-project-sidebar-width: calc(100 * var(--op-size-unit)); // 400px;
  --op-scope-chart-height: calc(63 * var(--op-size-unit)); // 252px;

  // Input Heights
  --op-input-height-small: 2.5rem; /* 25px */
  --op-input-height-medium: 2.6rem; /* 26px */
  --op-input-height-large: 3rem; /* 30px */
  --op-input-height-x-large: 8.4rem; /* 84px */

  // Radius
  --op-radius-medium: 5px;

  // Font Scale
  --op-font-scale-unit: 0.9rem; // 9px;
  --op-space-scale-unit: 0.65rem;
  // Font Family
  --op-font-family: 'Poppins', sans-serif;

  // Linear Variables
  --linear-button-shadow: lch(0 0 0 / 0.022) 0px 3px 6px -2px, lch(0 0 0 / 0.044) 0px 1px 1px;
  --linear-button-border: 0.5px solid lch(83.025 0 282.863);
}
