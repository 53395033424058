%badge-global {
  --lcv-badge-indicator-size: calc(var(--op-size-unit) * 2); // 8px

  font-size: var(--op-font-x-small);
  border: var(--linear-button-border);
  align-self: flex-start;
  gap: var(--op-space-x-small);
}

.badge {
  background-color: var(--op-color-neutral-plus-eight);
  color: var(--op-color-neutral-minus-four);
  font-weight: var(--op-font-weight-normal);
  white-space: pre-wrap;
}

.badge-notice {
  color: var(--op-color-neutral-plus-max);
}

.badge--section-header {
  align-self: baseline;
  font-weight: var(--op-font-weight-semi-bold);
  font-size: var(--op-font-x-small);
  border: var(--op-border-width) solid var(--op-color-border);
  border-radius: var(--op-radius-pill);
  padding: var(--op-space-x-small) var(--op-space-medium);
  background-color: var(--op-color-neutral-plus-six);
  color: var(--op-color-neutral-on-plus-six-alt);
}

.badge--indicator {
  --lcv-badge-indicator-size: calc(var(--op-size-unit) * 2); // 8px
  width: var(--lcv-badge-indicator-size);
  height: var(--lcv-badge-indicator-size);
  min-width: var(--lcv-badge-indicator-size);
  min-height: var(--lcv-badge-indicator-size);
  border-radius: var(--op-radius-circle);
}

.badge--pill {
  border-radius: var(--op-radius-pill);
}

.badge--dark {
  background-color: var(--op-color-neutral-plus-six);
  color: var(--op-color-neutral-on-plus-six);

  &.badge--hoverable {
    &:hover {
      background-color: var(--op-color-neutral-plus-eight);
      color: var(--op-color-neutral-minus-four);
    }
  }
}
