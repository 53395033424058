.sidebar {
  --__op-sidebar-width: 36rem;

  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  color: var(--op-color-on-background);
  background-color: var(--op-color-background);
  border-left: var(--op-border-width) solid var(--op-color-border);
  height: 100%;
  overflow: hidden;

  .sidebar__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: var(--op-border-width) solid var(--op-color-border);
    border-bottom: var(--op-border-width) solid var(--op-color-border);
    text-align: center;
    align-items: center;
    padding: var(--op-space-small);
  }
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  top: 0;
  gap: var(--op-space-small);

  border-bottom: var(--op-border-width) solid var(--op-color-border);
  padding: var(--op-space-large) var(--op-space-2x-large);

  .sidebar__chart {
    height: var(--op-scope-chart-height);
  }
}
